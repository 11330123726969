import React from "react";
import FAQBlock from "@components/FAQBlock";
import Hero from "@components/Hero";
import TextBox from "@components/TextBox";
import * as styles from "./styles.module.css";

const FAQPage = ({
    title,
    backgroundImage,
    intro,
    faqBlock
}) => {
    return (
        <>
        <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
        >
          <TextBox {...intro} className={styles.box} />
      </Hero>
            <FAQBlock {...faqBlock } className={styles.faq}/>
        </>
    );
};

export default FAQPage;