import React from "react";
import { graphql } from "gatsby";

import FAQPage from "@components/FAQPage";

const FAQ = ({ data }) => {
    const { pageData } = data;
  
    return <FAQPage {...pageData} />;
  };

  export const query = graphql`
  query FaqPageQuery {
    pageData: sanityFaqPage {
      title
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
      faqBlock {
         faqs {
            question 
            answer
         }
         backgroundColor {
          value
        }
      }
      intro {
        ...SanityTextBoxFragment
      }
    }
  }
`;

export default FAQ;