import React, { useState } from "react";
import "./styles.css";



const FAQ = ({ question, answer }) => {
  const [visibility, setVisibility] = useState("faq-answer-hidden");
  const [plusSign, setPlusSign] = useState("+");

  const changeVisibility = () => {
    if (visibility === "faq-answer-hidden") {
      setVisibility("faq-answer")
      setPlusSign("-")
    } else {
      setVisibility("faq-answer-hidden")
      setPlusSign("+")
    }
  }
  
  
  const classes = `faq-answer-styling ${visibility}`
  return (
    <div className="faq">
      <div className="question-and-answer-wrap">
        <div onClick={changeVisibility} className="question-wrap">
          <div className="faq-question">{question}</div>
          <div className="plus-sign">{plusSign}</div>
        </div>
      <div className={classes}>{answer}</div>
      </div>
    </div>
  );
};

export default FAQ;