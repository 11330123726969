import React, { useState } from "react";
import FAQ from "@components/FAQ";
import "./styles.css";


const FAQBlock = ({
  faqs
}) => {
  return (
    <>
      <div className="faq-wrap">
        {faqs.map(({ question, answer }) => (
          <div className="faqs">
            <FAQ question={question} answer={answer}/>
          </div>
        ))}
        
      </div>
    </>
  )
}

export default FAQBlock;